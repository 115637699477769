import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useProjectMapContext } from './../hooks/useProjectMapContext';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { debug, swalAlert } from './../../../utils';
import { cloneDeep, join } from 'lodash';
import API from './../../../api';
import { fetchUserDetails } from './../../../redux/userSlice';
import CustomAlert from './../../helpers/CustomAlert/CustomAlert';
import ResponsiveNavTabs from '../../helpers/ResponsiveNavTabs/ResponsiveNavTabs';
import { HtmlElement } from './../../helpers/HtmlElements/HtmlElements';
import ProjectParams from './inc/ProjetParams';
import ProjectMedias from './inc/ProjectMedias';
import ProjectMeta from './inc/ProjectMeta';
import { setProject } from '../../../redux/projectSlice';
const ModalProjectExport = ({ show, handleClose = null }) => {
  const user = useSelector((state) => state.user);
  const {
    userCan,
  } = useProjectMapContext();
  const project = useSelector(state => state.project);
  return (
    (userCan('editParams')) &&
    <Modal show={show} size='lg' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Export du projet "{project?.projName}"
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        EXPORT
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalProjectExport;
