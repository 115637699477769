// ProjectMapContext
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import 'leaflet-editable'
import { DeviceContext } from './../../../DeviceContext'
import { setConfig } from './../../../redux/configSlice';
import API from './../../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { setProject, updateProject } from '../../../redux/projectSlice';
const ProjectMapContext = createContext();
export const ProjectMapProvider = ({ children }) => {
  const mapRef = useRef(null); // Initialisation de ProjectMap avec useRef
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const project = useSelector(state => state.project);
  const user = useSelector(state => state.user);
  const userPreferences = useSelector(state => state.userPreferences);
  const current_filters = useSelector(state => state.userPreferences.projects?.[project.projId]?.current_filters) || [];
  const [itemSelected, setItemSelected] = useState(null);
  const [itemContextMenuOptions, setItemContextMenuOptions] = useState({ show: false });
  const [mapContextMenuOptions, setMapContextMenuOptions] = useState({ show: false });
  const [mapTypes, setMapTypes] = useState([]);
  const [mapTypeId, setMapTypeId] = useState('openStreetMap');
  const [mapType, setMapType] = useState({});
  const [showModalCreateProject, setShowModalCreateProject] = useState(false);
  const [showModalProjectParams, setShowModalProjectParams] = useState(false);
  const [showModalProjectExport, setShowModalProjectExport] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);
  const [activeLayer, setActiveLayer] = useState(null);
  const { isMobile, isTablet, isDesktop } = useContext(DeviceContext);
  const [uiMode, setUiMode] = useState(window.localStorage.getItem('projectUiMode') ? window.localStorage.getItem('projectUiMode') : (isDesktop ? 'desktop' : 'field '));
  useEffect(() => {
    API.get('/config/map_types').then(response => {
      setMapTypes(response.data);
      dispatch(setConfig({ map_types: response.data }));
    }).catch(e => { console.error(e); }
    );
  }, []);
  useEffect(() => {
    window.localStorage.setItem('projectUiMode', uiMode)
  }, [uiMode]);
  useEffect(() => {
    const current_filters = userPreferences.projects?.[project.projId]?.current_filters || [];
    if (JSON.stringify(project.filters) !== JSON.stringify(current_filters)) {
      dispatch(updateProject({ filters: current_filters }))
    }
  }, [current_filters])
  useEffect(() => {
    if (itemContextMenuOptions.show === true) {
      setMapContextMenuOptions({ show: false });
    }
  }, [itemContextMenuOptions]);
  useEffect(() => {
    setMapType(mapTypes.find(mt => mt.id === 'googleSatellite'));//
  }, [mapTypes]);
  useEffect(() => {
    setMapType(null);
    window.setTimeout(() => {
      if (mapTypes.length) {
        setMapType(mapTypes.find(mt => mt.id === mapTypeId));//
      }
    }, 100)
  }, [mapTypeId]);
  useEffect(() => {
    setItemContextMenuOptions({ show: false });
    setMapContextMenuOptions({ show: false });
  }, [selectedTool]);
  useEffect(() => {
    if (itemSelected && !itemSelected.projMeta && project.projMeta && project.projMeta[itemSelected.itType]) { // 
      setItemSelected({ ...itemSelected, projMeta: project.projMeta[itemSelected.itType] })
    }
  }, [itemSelected]);
  useEffect(() => {
    setActiveLayer(project.layers.find(l => l.active == true));
  }, [project.layers]);
  const getProject = async (projectId) => {
    try {
      const resp_project = await API.get(`/project/${projectId}`);
      const project = resp_project.data;
      const resp_items_meta = await API.get(`/project/${projectId}/items_meta`);
      const items_meta = resp_items_meta.data;
      project.layers = [];
      project.items.forEach((it, index) => {
        project.items[index].meta = items_meta[it.itId] || {}
        if (it.itType == 'layer') {
          project.layers.push({ ...it });
        }
      })
      dispatch(setProject(project));
    } catch (error) {
    }
  };
  const setMapRef = (map) => {
    mapRef.current = map;
    mapRef.current.editTools = new L.Editable(mapRef.current, {
    });
  };
  const selectItem = (itemId, event) => {
  }
  const handleMapMenuClick = (action) => {
    setMapContextMenuOptions({ show: false });
  };
  const handleModalCreateProjectClose = () => {
    if (window.localStorage.getItem('currentProjectId')) {
      setShowModalCreateProject(false);
      navigate('/project/map/' + window.localStorage.getItem('currentProjectId'));
    }
    if (window.localStorage.getItem('currentProjectId')) {
      setShowModalCreateProject(false);
    }
  };
  const handleProjectCreated = (createdProject) => {
    setShowModalCreateProject(false);
    navigate('/project/map/' + createdProject.projId);
  };
  const handleProjectUpdated = () => {
    setShowModalProjectParams(false)
  }
  const actionsToPermissions = {
    editParams: ['admin'],
    removeItem: ['admin', 'owner', 'editor'],
    editLayers: ['admin', 'owner', 'editor'],
    editItem: ['admin', 'owner', 'editor'],
    showItem: ['admin', 'owner', 'editor'],
  };
  const userCan = (action) => {
    const requiredPermissions = actionsToPermissions[action] || [];
    return user.userRoles?.Admin || project.userPermissions?.some(permission => requiredPermissions.includes(permission));
  };
  useEffect(() => { }, [itemSelected]);
  return (
    <ProjectMapContext.Provider value={{
      project, getProject, setProject,
      mapType, setMapTypeId, mapRef, setMapRef, handleMapMenuClick,
      selectItem, itemSelected, setItemSelected,
      activeLayer, setActiveLayer,
      itemContextMenuOptions, setItemContextMenuOptions,
      mapContextMenuOptions, setMapContextMenuOptions,
      showModalCreateProject,
      setShowModalCreateProject,
      handleModalCreateProjectClose,
      setShowModalProjectParams,
      showModalProjectParams,
      setShowModalProjectExport,
      showModalProjectExport,
      handleProjectCreated,
      handleProjectUpdated,
      userCan,
      uiMode, setUiMode,
      selectedTool, setSelectedTool,
    }}>
      {children}
    </ProjectMapContext.Provider>
  );
};
export const useProjectMapContext = () => useContext(ProjectMapContext);
