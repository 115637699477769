import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { debug, isDev } from './../../../utils';
import './modal-item-edit.css'
import TreeFrom from './../items/forms/trees/TreeForm'
import TreesGroupForm from './../items/forms/trees/TreesGroupForm'
import MarkerForm from './../items/forms/markers/MarkerForm';
import LayerForm from './../items/forms/layers/LayerForm';
import LineForm from '../items/forms/lines/LineForm';
import useItemsService from 'components/projects/hooks/useItemsService';
import API from 'api';
import ProjectIcons from '../helpers/ProjectIcons';
const ModalItemEdit = ({ show, item, handleClose = null, onUpdated }) => {
  const { IconComponent } = useItemsService();
  const [itemCopy, setItemCopy] = useState({ initialized: false, meta: {} });
  useEffect(() => {
  }, [])
  useEffect(() => {
  }, [itemCopy])
  useEffect(() => {
    if (item?.itId && item?.itId !== '' && itemCopy.initialized == false) {
      API.get(`/item/${item.itId}`).then(response => {
        setItemCopy({ ...response.data, initialized: true });
      }).catch(error => {
      })
    } else {
      setItemCopy({ ...item, initialized: true });
    }
  }, [item]);
  const handleUpdate = (updatedItem) => {
    setItemCopy({ ...itemCopy, ...updatedItem })
  }
  return (
    itemCopy && itemCopy.initialized &&
    <Modal show={true} onHide={handleClose} className={`modal-${itemCopy?.itType}-edit`}>
      <Modal.Header closeButton>
        <ProjectIcons type={item.itType} size={20} className='me-1' />
        {itemCopy.itName ? <h6 className='p-0 m-0'>{itemCopy.itRef} - {itemCopy.itName}</h6> : null}
      </Modal.Header>
      <Modal.Body className='d-flex flex-column'>
        {itemCopy.itType === 'layer' && <LayerForm item={itemCopy} onUpdate={handleUpdate} />}
        {itemCopy.itType === 'tree' && <TreeFrom item={itemCopy} onUpdate={handleUpdate} />}
        {itemCopy.itType === 'alignment' && <TreesGroupForm item={itemCopy} onUpdate={handleUpdate} />}
        {itemCopy.itType === 'marker' && <MarkerForm item={itemCopy} onUpdate={handleUpdate} />}
        {itemCopy.itType === 'line' && <LineForm item={itemCopy} onUpdate={handleUpdate} />}
        {itemCopy.itType === 'poligon' && <MarkerForm item={itemCopy} onUpdate={handleUpdate} />}
        {itemCopy.itType === 'circle' && <MarkerForm item={itemCopy} onUpdate={handleUpdate} />}
      </Modal.Body>
      <Modal.Footer>
        {isDev() &&
          <div className='flex-grow-1 text-left'>
            {itemCopy.itId} - {itemCopy.itStatus}
          </div>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ModalItemEdit;