import React, { useEffect, useState } from 'react';
import { useProjectMapContext } from '../../hooks/useProjectMapContext';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import useItemsService from 'components/projects/hooks/useItemsService';
import 'css/ui-toolbar.css';

import L from 'leaflet';
import ProjectIcons from 'components/projects/helpers/ProjectIcons';
const ProjectItemsToolbar = ({ className = '' }) => {
  const { mapRef, userCan, selectedTool, setSelectedTool } = useProjectMapContext();


  const tools = [
    { type: 'tree', label: 'Ajouter un sujet' },
    { type: 'alignment', label: 'Ajouter un alignement' },
    { type: 'woodedarea', label: 'Ajouter un espace boisé', disabled: true },
    { type: 'marker', label: 'Ajouter un marqueur' },
    { type: 'line', label: 'Ajouter une ligne' },
    { type: 'polygon', label: 'Ajouter un polygone' },
    { type: 'circle', label: 'Ajouter un cercle' },
  ];
  const [displayedTool, setDisplayedTool] = useState(tools[0]);
  useEffect(() => {

  }, [displayedTool]);
  const handleClick = (tool_type) => {
    // const startPolygonDraw = () => {
    // new L.Draw.Polygon(mapRef.current).enable();
    // };
    if (selectedTool == tool_type) {
      setSelectedTool(null);
    } else {
      setSelectedTool(tool_type)
    }
    if (tool_type == 'brain') {
      return
    }
    setIsDropdownVisible(false);
    //  onClick={e => setSelectedTool('line')}
  }
  // État local pour gérer la visibilité de la liste déroulante sur mobile
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // Trouve l'outil sélectionné ou le premier outil comme fallback

  return (
    userCan('editItem') &&
    <div className={`ui-toolbar position-relative ${isDesktop ? 'desktop' : 'mobile'}`} id="ProjectItemsToolbar">
      {(isDesktop) ? (

        // Affichage sur desktop
        <ul className='list-unstyled m-0 px-0 d-flex align-items-center me-2 icons'>
          {tools.map(tool => (
            <li key={tool.type}
              className={`p-1 ${selectedTool === tool.type ? 'selected-tool' : ''} ${tool.disabled ? 'disabled' : ''}`}
              data-tool={tool.type}
              title={tool.label}
              onClick={() => !tool.disabled ? handleClick(tool.type) : {}}
            >
              <ProjectIcons type={tool.type} size={20} label={tool.label} />
            </li>
          ))}
        </ul>
      ) : (

        // Affichage sur mobile
        <>
          <div className={`d-flex align-items-center`} >
            {/* Icône de l'outil sélectionné ou du premier outil */}
            <span
              className={`p-1 ${selectedTool === displayedTool.type ? 'selected-tool' : ''} ${displayedTool.disabled ? 'disabled' : ''}`}
              onClick={() => handleClick(displayedTool.type)}
            >
              <ProjectIcons type={displayedTool.type} size={20} label={displayedTool.label} />
            </span>
            {/* Carret down pour ouvrir la liste déroulante */}
            <button onClick={() => setIsDropdownVisible(!isDropdownVisible)} style={{ background: 'none' }}>
              &#9660; {/* Symbole de caret down, ajuste selon tes besoins */}
            </button>
          </div>
          {isDropdownVisible && (
            <ul className='list-unstyled'>
              {tools.map(tool => (
                <li key={tool.type}
                  className={`p-1 ${tool.disabled ? 'disabled' : ''}`}
                  onClick={() => { if (!tool.disabled) { setDisplayedTool(tool); handleClick(tool.type) } }}
                >
                  <ProjectIcons type={tool.type} size={20} label={tool.label} />
                </li>
              ))}
            </ul>
          )}
        </>

      )}
    </div>
  )
};
export default ProjectItemsToolbar;
