import React, { useEffect, useState } from 'react';
import { useProjectMapContext } from '../../hooks/useProjectMapContext';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import useItemsService from 'components/projects/hooks/useItemsService';


const ProjectFunctions = ({ className = '' }) => {
  const { mapType,
    setMapTypeId, getProject,
    setShowModalProjectExport,
    showModalProjectExport,
    userCan,
    uiMode,
    setUiMode,
    selectedTool,
    setSelectedTool,
  } = useProjectMapContext();
  return (
    <div id="toolbar-project-functions" className={`ui-toolbar d-flex flex-row ${className}`}>
      <div className="dropdown">
        <span className=" dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
          Outils
        </span>
        <ul className="dropdown-menu small p-0 overflow-hidden" aria-labelledby="dropdownMenuButton">
          <li>
            <span className="dropdown-item pointer" type="button" onClick={e => setShowModalProjectExport(true)}>
              Exporter
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
};
export default ProjectFunctions;
