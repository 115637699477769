import React, { useEffect, useState, useContext } from 'react';
import { DeviceContext } from './../../DeviceContext'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useProjectMapContext } from './hooks/useProjectMapContext';
import API from 'api';
import { setProject } from './../../redux/projectSlice'; // Importe les actions Redux
import { loadUserPreferences } from './../../redux/userPreferencesSlice';
import ModalCreateProject from './modals/ModalCreateProject';
import ModalProjectParams from './modals/ModalProjectParams';
import ModalProjectExport from './modals/ModalProjectExport';
import ModalItemEdit from './modals/ModalItemEdit';
import ProjectTopbar from './toolbars/ProjectTopbar';
import UiModeField from './uiModes/UiModeField'
import { debug, swalAlert } from './../../utils';
import 'leaflet/dist/leaflet.css';
import './css/project-map.css';
import './css/toolbars.css';
import './../../css/context-menus.css';
import UiModeDesktop from './uiModes/UiModeDesktop';
const ProjectMapInner = () => {
  const dispatch = useDispatch(); // Initialise le dispatch Redux
  const navigate = useNavigate();
  const { isMobile, isTablet, isDesktop } = useContext(DeviceContext);
  const user = useSelector((state) => state.user);
  const { projId } = useParams();
  const {
    mapType, setMapRef, itemSelected, setItemSelected, itemContextMenuOptions,
    getProject,
    alignemt, setAlignment,
    setItemContextMenuOptions, mapContextMenuOptions, setMapContextMenuOptions,
    showModalCreateProject,
    setShowModalCreateProject,

    handleModalCreateProjectClose,
    setShowModalProjectParams,
    showModalProjectParams,

    setShowModalProjectExport,
    showModalProjectExport,

    handleProjectCreated,
    handleProjectUpdated,
    userCan,
    uiMode, setUiMode,
    apply_items_filters,
  } = useProjectMapContext();
  useEffect(() => { //
    setUiMode(
      window.localStorage.getItem('projectUiMode') ? window.localStorage.getItem('projectUiMode') : (isDesktop ? 'desktop' : 'field')
    )
  }, [])
  const project = useSelector(state => state.project);
  const config = useSelector(state => state.config)

  useEffect(() => {
    if (projId) {
      if (projId === 'new') {
        setShowModalCreateProject(true);
      } else {
        getProject(projId);
        window.localStorage.setItem('currentProjectId', projId)
      }
    } else {
      if (window.localStorage.getItem('currentProjectId')) {
        navigate('/project/map/' + window.localStorage.getItem('currentProjectId'));
      } else if (user.details?.projects.length > 0) {
        navigate('/project/map/' + user.details?.projects[0].projId);
      }
    }
  }, [projId]);
  /*
  const getProject = async (projectId) => {
    try {
      const resp_project = await API.get(`/project/${projectId}`);
      const project = resp_project.data;
      const resp_items_meta = await API.get(`/project/${projectId}/items_meta`);
      const items_meta = resp_items_meta.data;
      project.layers = [];
      project.items.forEach((it, index) => {
        project.items[index].meta = items_meta[it.itId] || {}
        if (it.itType == 'layer') {
          project.layers.push({ ...it });
        }
      })
      dispatch(setProject(project));
    } catch (error) {
    }
  };
  */
  return (
    <div className='project-map-inner'>
      <ProjectTopbar options={{}} />
      {project?.projId && <>
        {uiMode == 'desktop' &&
          <UiModeDesktop />
        }
        {uiMode == 'field' &&
          <UiModeField />
        }
      </>
      }
      {(userCan('editItem') && itemSelected) && <ModalItemEdit item={itemSelected} handleClose={e => setItemSelected(null)}></ModalItemEdit>}
      {(userCan('editParams') && showModalCreateProject) &&
        <ModalCreateProject show={showModalCreateProject} handleClose={e => { navigate(-1); setShowModalCreateProject(false) }} onCreated={handleProjectCreated} />
      }
      {(userCan('editParams') && showModalProjectParams) &&
        <ModalProjectParams
          show={showModalProjectParams}
          project={project}
          handleClose={() => setShowModalProjectParams(false)}
          onUpdated={handleProjectUpdated} />
      }
      {(showModalProjectExport) &&
        <ModalProjectExport
          show={showModalProjectExport}
          project={project}
          handleClose={() => setShowModalProjectExport(false)}
          onUpdated={handleProjectUpdated} />
      }
    </div>
  );
};
export default ProjectMapInner;
